import React from "react"
import { Helmet } from "react-helmet"
import classes from "./Exterior.module.scss"
import Phone from '../../components/Shared/Phone'
import slide1 from '../../assets/images/slIslandShores1.jpg'
import slide3 from '../../assets/images/slSanPerdido2.jpg'
import slide4 from '../../assets/images/slIslandShores2.jpg'
import slide5 from '../../assets/images/slMoorings2.jpg'
import eifs from "../../assets/images/service2/eifs.jpg"
import coatings from "../../assets/images/service2/exteriorCoatings.jpg"
import ptraffic from "../../assets/images/service2/pedestrianTraffic.jpg"

const quoteText=(
  <div>
    <p>Read through our catalogue of options below, and contact us today for a quote or consultation.</p>
    <p><Phone where="aboutVideo" /></p>
  </div>
)

const exterior = () => {
  return (
    <div className={classes.Exterior}>
      <Helmet>
        <title>Exterior Upgrades, Repairs, & Waterproofing | MD Thomas Construction</title>
        <meta name="description" content="MDTC helps protect & transform your property. We repair stucco, EIFS, & concrete, replace doors, windows, & handrails, provide waterproofing, & so much more." />
      </Helmet>

      <div className={[classes.kpGrid, classes.ipIntro, classes.exterior].join(' ')}>
        <div className={[classes.medDef, classes.class2].join(' ')}>
          <div className={[classes.col, classes.grid38, classes.left].join(' ')}>
            <h2 className={classes.cat}>MD Thomas Construction LLC</h2>
            <h1>We provide comprehensive <span className={classes.color1}>exterior restoration</span> and <span className={classes.color1}>waterproofing</span> services.</h1>
            <div className={classes.mobSkip}><a href="#exteriorServiceList" className={classes.mdLink} data-uk-scroll>Skip to Services</a></div>
          </div>

          <div className={[classes.col, classes.grid57, classes.right, classes.tmarginSm].join(' ')}>
            <p>
              Because of our considerable experience with the Alabama Gulf Coast &amp; Florida Panhandle — both as a professional contracting company, and as 
              residents — we are very familiar with the harsh effects our beautiful climate can have on outdoor structures. That is why we specialize in exterior 
              preservation and restoration, with powerful options that help prevent damage to your building and outdoor areas, as well as treat and repair 
              existing issues. 
            </p>
          </div>
        </div>
      </div>

      <div className={[classes.fullRow, classes.midExt].join(' ')}>
        <div className={classes.insideN}>
          <div className={classes.extSlideWrap}>
            <div id="extSlideshow" className={[classes.extSlideshow, "XXuk-position-relative"].join(' ')} data-uk-slideshow="animation:fade;autoplay:true;">
              <div className={classes.slideText}>{quoteText}</div>
              <div className={classes.maxHeight}>
                <ul className={[classes.slItems, "uk-slideshow-items"].join(' ')}>
                  <li className={[classes.ssImage, classes.i2].join(' ')}><img src={slide3} alt="EIFS repairs, and exterior coatings" className={"uk-position-bottom"} /></li>
                  <li className={[classes.ssImage, classes.i0].join(' ')}><img src={slide1} alt="Beach condo after stucco repairs" className={"uk-position-center"} /></li>
                  <li className={[classes.ssImage, classes.i4].join(' ')}><img src={slide5} alt="Waterfront property after repairs" className={"uk-position-center"} /></li>
                  <li className={[classes.ssImage, classes.i3].join(' ')}><img src={slide4} alt="MDTC client, condo on the beach" className={"uk-position-center"} /></li>
                </ul>
              </div>          
            
              <ul className={[classes.dotNav, "uk-slideshow-nav uk-dotnav XXuk-flex-center"].join(' ')}></ul>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.scrollSec} id="exteriorServiceList"></div>
      <div className={[classes.sectionish, classes.serveList].join(' ')}>
        <div className={[classes.flexRow, classes.row1].join(' ')}>
          <div className={[classes.col, classes.col1, classes.textCol, classes.erest].join(' ')}>
            <div className={classes.tbox}>
              <h4>Exterior Restoration</h4>
              <p>Stucco Texture Repair</p>
              <p>EIFS Repair &amp; Replacement</p>
              <p>Metal &amp; Fiberglass Door Replacement</p>
              <p>Window &amp; Sliding Glass Door Replacement</p>
              <p>Handrail Repair &amp; Replacement</p>
              <p>Rappelling Pressure Washing</p>
              <p>Concrete Repair</p>
            </div>
          </div>

          <div className={[classes.col, classes.col2, classes.imageCol].join(' ')}>
            <img src={eifs} alt="MD Thomas - exterior restoration in Orange Beach" className={classes.img1} />
          </div>

          <div className={[classes.col, classes.col3, classes.textCol, classes.wproof].join(' ')}>
            <div className={classes.tbox}>
              <h4>Waterproofing</h4>
              <p>Pedestrian Traffic Coatings</p>
              <p>Exterior Coatings</p>
              <p>Vehicular Traffic Coatings</p>
              <p>Window Glazing</p>
              <p>Cementitious Waterproofing</p>
              <p>Expansion Joints Sealants</p>
            </div>
          </div>
        </div>

        <div className={[classes.flexRow, classes.row2].join(' ')}>
          <div className={[classes.col, classes.col4, classes.imageCol].join(' ')}><img src={coatings} alt="MD Thomas Construction - exterior coating" className={classes.img2} /></div>
          
          <div className={[classes.col, classes.col5, classes.textCol, classes.text].join(' ')}>
            <div className={classes.tbox}>
              <p>
                Our typical projects range anywhere from minor improvements, upgrades, and repairs, 
                to a complete exterior overhaul. We work closely with our clients, and extend our knowledge and experience to determine the best solution for their 
                specific needs.
              </p>
            </div>
          </div>

          <div className={[classes.col, classes.col6, classes.imageCol].join(' ')}><img src={ptraffic} alt="MD Thomas Construction - Orange Beach condo" className={classes.img3} /></div>
        </div>
      </div>

      <div className={[classes.fullRow, classes.mobImageRow].join(' ')}>
        <img src={eifs} alt="MD Thomas Construction - EIFS" className={classes.img1} />
        <img src={coatings} alt="MD Thomas Construction - coating restoration" className={classes.img2} />
        <img src={ptraffic} alt="MD Thomas - pedestrian walkway update" className={classes.img3} />
      </div>
    </div>
  )
}
export default exterior