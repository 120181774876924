import React from "react"
import classes from './ServiceText.module.scss'

const serviceText = (props) => {
  return (
    <div className={[classes.ServiceText, classes[props.where], classes[props.addClass], classes[props.custom ? 'customSpan' : 'stdSpan']].join(' ')}>
      <p>{props.addBefore !== null ? props.addBefore : null} We are committed to high quality — from the products we use, to the services we perform. We are also committed to you. We work hard to earn your trust, and 
        achieve your goals for the project. {props.addAfter !== null ? props.addAfter : null} 
      </p>
    </div>
  )
}
export default serviceText