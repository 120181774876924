import React from "react"
import { Link } from "react-router-dom"
import classes from './Footer.module.scss'
import Social from "../Social/Social"
import Phone from "../Shared/Phone"
import NavItems from "../Header/NavItems"
import Email from "../Shared/Email"
import Logo from "../../assets/images/mdtcLogoW.png"

const licenses = (
  <div className={classes.licenses}>
    <p>Alabama General Contractor License<br/> #39995</p>
    <p>Florida General Contractor License<br/> #CGC1525385</p>
  </div>
)

const footer = (props) => {
  return (
    <footer className={[classes.Footer, classes[props.FooterType], classes.kpGrid, classes.v3].join(' ')}>
      <div className={classes.topFooter}>
        <div className={[classes.footWrap, classes.insideTw].join(' ')}>
          <div className={classes.tfwRow}>
            <div className={[classes.col, classes.col1].join(' ')}>
              <Link to="/" className={[classes.ftLogo, classes.white].join(' ')}><img src={Logo} alt="MDTC Logo" className={classes.imgResponsive} /></Link>
              <p className={classes.summ}>
                We are a commercial construction company specializing in exterior restoration and marine 
                construction throughout the Alabama Gulf Coast and the Florida Panhandle.
              </p>
            </div>

            <div className={[classes.col, classes.col2].join(' ')}>
              <p>4401 Money Bayou Drive<br/> Orange Beach, AL 36561</p>
              <p className={classes.tmargin}><Email where="footer" /></p>
              <p className={classes.il}><Phone where="footer" /></p>
              <Social where="footer" />
              {licenses}
            </div>

            <div className={[classes.col, classes.col3].join(' ')}>
              <Link to="/" className={[classes.ftLogo, classes.white].join(' ')}><img src={Logo} alt="MDTC Logo" className={classes.imgResponsive} /></Link>
              {licenses}
            </div>
          </div>
        </div>
      </div>

      <div className={[classes.fullRow, classes.btmFooter].join(' ')}>
        <div className={[classes.btmWrap, classes.insideTw].join(' ')}>
          <NavItems where="footer" navClass="ftMenu" addClass="not"/>
          <p className={[classes.copy, classes.btmCopy].join(' ')}>© {(new Date().getFullYear())} MD Thomas Construction LLC. <br/>Design by <a href="http://hsdesignhouse.com" target="_blank" rel="noopener noreferrer">High Seas Design House.</a></p>
        </div>
      </div>

      <div className={classes.eoe}>
        <div className={classes.insideN}>
          <p>** MDTC is an equal opportunity employer. We do not discriminate in employment on the basis of race, color, religion, sex (including pregnancy and gender identity), national origin, political affiliation, sexual orientation, marital status, disability, genetic information, age, membership in an employee organization, retaliation, parental status, military service, or other non-merit factor.</p>
        </div>
      </div>
    </footer>
  )
}

export default footer