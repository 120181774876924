import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"
import classes from "./Marine.module.scss"
import Video from "../../components/Video/Video"
import ServiceText from "../../components/ServiceText/ServiceText"
import marineVideo from "../../assets/images/pierBeforeAfter.mp4"
import still from '../../assets/images/pierVideoShot.png'
import dredge from "../../assets/images/service2/dredge.jpg"
import pier from "../../assets/images/service2/pier2.jpg"
import seawall from "../../assets/images/service2/seawall.jpg"
import riprap from "../../assets/images/service2/riprap.jpg"

const quoteText=(
  <span className="introQuote">Read through our catalogue of options below, and <Link to="/contact" className="textLink">contact us</Link> today for a quote or consultation.</span>
)

const marine = () => {
  return (
    <div className={classes.Marine}>
      <Helmet>
        <title>Dredging, Piers, Boat Ramps & More Marine Construction</title>
        <meta name="description" content="MD Thomas Construction helps Gulf Coast clients develop, upgrade, and protect waterfront properties. We provide dredging, seawalls, piers, boat ramps, and more." />
      </Helmet>

      <div className={[classes.kpGrid, classes.ipIntro, classes.marine].join(' ')}>
        <div className={[classes.medDef, classes.class2].join(' ')}>
          <div className={[classes.col, classes.left].join(' ')}>
            <h2 className={classes.cat}>MD Thomas Construction LLC</h2>
            <h1>We provide comprehensive, licensed <span className={classes.color1}>marine construction</span> services throughout the 
            <span className={classes.color1}> Alabama Gulf Coast</span> &amp; the <span className={classes.color1}> Florida Panhandle</span></h1>
            <div className={classes.mobSkip}><a href="#marineList" className={classes.mdLink} data-uk-scroll>Skip to Services</a></div>
          </div>

          <div className={[classes.col, classes.right, classes.tmarginSm].join(' ')}>
            <p>Our team specializes in helping clients to develop, upgrade, and protect their waterfront structures and properties. We are highly experienced, and 
              capable of accommodating marine construction projects of all types and all sizes, including dredging and marine recovery. We serve both the public and private markets.</p>
            <ServiceText where="marine" addAfter={quoteText} custom={true} addClass="marineQt" />
          </div>
        </div>
      </div>

      <div className={[classes.fullRow, classes.midExt].join(' ')}>
        <div className={classes.insideN}>
          <Video
            vidSrc={marineVideo}
            vidClass="marineIntroB"
            frameName="video"
            Loop="true"
            image={still}
            withOver="true"
          />
        </div>
      </div>

      <div className={classes.services}>
        <div className={[classes.sectionWrap, classes.medDef].join(' ')}>
          <div className={[classes.item, classes.combo, classes.image].join(' ')}>
            <div className={classes.textBox}>
              <h4>Marine Construction</h4>
              <ul>
                <li>Dredging</li>
                <li>Riprap</li>
                <li>Piers</li>
                <li>Seawalls</li>
                <li>Pilings</li>
                <li>Boat Ramp</li>
                <li>Recovery</li>
              </ul>
            </div>
            <img src={seawall} alt="Sea wall construction on the Gulf Coast" className={classes.img2} />
            <img src={riprap} alt="MD Thomas Construction - Riprap services" className={classes.img2} />
          </div>

          <div className={[classes.item, classes.i2, classes.image].join(' ')}>
            <img src={dredge} alt="MDTC Dredging services in Orange Beach" className={classes.img2} />
            <img src={pier} alt="Pier construction in Gulf Shores, AL" className={classes.img2} />
          </div>
        </div>
      </div>
    </div>
  )
}
export default marine