import React, {Component} from 'react'
import { Link } from "react-router-dom"
import classes from "./Header.module.scss"
import NavItems from "./NavItems"
import Drawer from "./Drawer"
import Phone from "../Shared/Phone"
import Email from "../Shared/Email"
// import Social from "../Social/Social"
import logo from "../../assets/images/mdtcLogo.png"

class Header extends Component {
  render() {
    return (
      <header className={[classes.Header, classes.style2, classes[this.props.HeaderType]].join(' ')}>
        <div className={[classes.topbar, classes.mbPhone].join(' ')}>
          <Link to="/contact" className={classes.tbText}>Contact Us for a Quote</Link>
          <span className={[classes.dot, classes.d1].join(' ')}>&middot;</span>
          <span className={classes.tbPhone}><Phone where="header" /></span>
          <span className={[classes.dot, classes.d2].join(' ')}>&middot;</span>
          <span className={classes.tbEmail}><Email where="header" /></span>
        </div>

        <div className={classes.topRow}>
          <div className={[classes.topWrap, classes.medDef].join(' ')}>
            <div className={[classes.topItem, classes.topLink, classes.tiEmail].join(' ')}><Email where="header" /></div>
            <div className={[classes.topItem, classes.logo].join(' ')}>
              <Link to="/" className={classes.logoLink}><img src={logo} alt="MDTC Logo" className={[classes.imgResponsive, classes.ilLogo].join(' ')} /></Link>
            </div>
            <div className={[classes.topItem, classes.topLink, classes.tiPhone].join(' ')}>
              <span>Contact Us for a Quote: <Phone where="header" /> </span>
            </div>

            <div className={[classes.topItem, classes.mobNav].join(' ')}><Drawer /></div>

          </div>
        </div>
        <nav className={[classes.navMm, "uk-navbar-container"].join(' ')} data-uk-navbar>
          <div className={[classes.inside, classes.hdWrap].join(' ')}>
            <NavItems navClass="navBarCustom" addClass="uk-navbar-nav" />
          </div>
        </nav>
      </header>
    )
  }
}
export default Header