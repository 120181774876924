import React from "react"
import classes from './Social.module.scss'
const social = (props) => {
  return (
    <ul className={[classes.Social, classes[props.where]].join(' ')}>
      <li><a href="https://www.instagram.com/mdtcoba/" target="_blank" rel="noopener noreferrer">
        <span className={classes.SocialIcon} aria-label="MDTC Instagram Link" uk-icon="icon: instagram"></span>
      </a></li>
      <li><a href="https://www.facebook.com/mdtconstruction" target="_blank" rel="noopener noreferrer">
        <span className={classes.SocialIcon} aria-label="MDTC Facebook Link" uk-icon="icon: facebook"></span>
      </a></li>
      <li><a href="https://id.linkedin.com/company/md-thomas-construction-inc" target="_blank" rel="noopener noreferrer">
        <span className={classes.SocialIcon} aria-label="MDTC LinkedIn Link" uk-icon="icon: linkedin"></span>
      </a></li>
    </ul>
  )
}
social.defaultProps = {
  email: "false"
}
export default social