import React, {Component} from 'react'
import { Routes, Route, useNavigate, useLocation } from "react-router-dom"
import { TinyButton as ScrollUpButton } from "react-scroll-up-button"
import classes from './App.module.scss'
import Header from "./components/Header/Header"
import Footer from "./components/Footer/Footer"
import Home from './hoc/Home/Home'
import About from './hoc/About/About'
import Contact from './hoc/Contact/Contact'
import Exterior from './hoc/Exterior/Exterior'
import Marine from './hoc/Marine/Marine'
import JobApplication from './hoc/FormPages/JobApplication'
// import Subcontractors from './hoc/FormPages/Subcontractors'
// import Fallback from './hoc/Fallback/Fallback'

export function withRouter( Child ) {
  return ( props ) => {
    const location = useLocation()
    const navigate = useNavigate()
    return <Child { ...props } navigate={ navigate } location={ location } />
  }
}

class App extends Component {
  render() {
    let routes = (
      <Routes>
        <Route exact={true} path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/company" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/exterior" element={<Exterior />} />
        <Route path="/exterior-restoration" element={<Exterior />} />
        <Route path="/exterior-services" element={<Exterior />} />
        <Route path="/marine" element={<Marine />} />
        <Route path="/marine-construction" element={<Marine />} />
        <Route path="/careers" element={<JobApplication />} />
        {/* <Route path="*" component={<Fallback />} /> */}
      </Routes>
    )

    const HeadStyle = () => {
      if (window.location.pathname === '/') {
        return (<Header HeaderType="altMargin" />)
      }
      return (<Header HeaderType="defaultStyle" />)
    }
    const SneakyHeader = withRouter(HeadStyle)

    return (
      <div className={classes.mdtcWrap}>
        <SneakyHeader />
        <main className={classes.MainContent}>
          {routes}
        </main>
        <Footer />
        
        <ScrollUpButton
          ContainerClassName={classes.ToTopBtn}
          TransitionClassName="MyOverRideTransitionedClass"
          style={{ fill: 'rgb(255,255,255)', backgroundColor: '#13223f', padding: '0px 5px', bottom: '15px' }}
          EasingType='linear'
        />
      </div>
    )
  }
}
export default App