import React from "react"
import { Helmet } from "react-helmet"
import classes from './Home.module.scss'
import { Link } from "react-router-dom"
import Video from "../../components/Video/Video"
// import introVid from "../../assets/images/condoIntro.mp4"
import introVid from "../../assets/images/homepageVideo.mp4"
import still from '../../assets/images/homeVideoShot1.png'
import mcPhoto from '../../assets/images/homeDredge4sq.jpg'
import erPhoto from '../../assets/images/homeExterior.jpg'
import erPhotoM from '../../assets/images/homeExteriorM.jpg'
// import slide2 from '../../assets/images/homeVistaBella.jpg'
import fullImage from '../../assets/images/homeIslandShores1.jpg'

const headline=(
  <div>
    <h2><span>MD Thomas<br/> Construction LLC</span></h2>
    <h1>
      Proudly Serving the <br className={classes.new1}/>
      Gulf Coast of Alabama <br className={classes.brMob} /> and
      Florida Panhandle 
      <br className={classes.new2}/><span className={classes.cat}>for Over 20 Years</span>
    </h1>
  </div>
)

const home = () => {
  return (
    <div className={classes.Home}>
      <Helmet>
        <title>Exterior Restoration & Marine Construction on the Gulf Coast | MD Thomas Construction</title>
        <meta name="description" content="MD Thomas Construction provides expert commercial and marine construction services along the Gulf Coast of Alabama and Florida." />
      </Helmet>

      <div className={classes.introVideoSec}>
        <Video
          vidSrc={introVid}
          vidClass="siteIntro"
          frameName="video"
          Loop={true}
          image={still}
          vidText={headline}
          withOver="true"
          title="Stucco restoration, exterior coatings, and construction in Orange Beach"
        />
      </div>

      <div className={classes.homeLay}>
        <div className={[classes.fullRow, classes.hlService, classes.color2].join(' ')}>
          <p className={classes.rotitle}>Specialties</p>

          <div className={[classes.medDef, classes.bodyText].join(' ')}>
            <div className={[classes.serveItem, classes.item1].join(' ')}>
              <div className={[classes.stackImg, classes.si1].join(' ')}>
                <img src={erPhoto} alt="Exterior restoration Orange Beach, AL" className={classes.item1img} />
              </div>

              <div className={[classes.stackText, classes.st1].join(' ')}>
                <div className={classes.stInner}>
                  <h4>Exterior<br/> Restoration</h4>
                  <p>
                    At MD Thomas Construction, we focus on preserving and restoring the exterior of your property. Our 
                    services are designed to safeguard your structure and prevent potential damage. From minor repairs 
                    to major upgrades, our team is prepared to handle all aspects of maintaining the value, safety, and 
                    integrity of your property.
                  </p>
                  <div className={classes.btnRow}><Link to="/exterior-services" className={classes.mdLink2Rev}>Learn More</Link></div>
                </div>
              </div>
            </div>
            <div className={classes.clearfix}/>

            <div className={[classes.serveItem, classes.item2].join(' ')}>
              <div className={[classes.stackImg, classes.si2].join(' ')}>
                <img src={mcPhoto} alt="MDTC dredging in Gulf Shores, AL" />
              </div>

              <div className={[classes.stackText, classes.st2].join(' ')}>
                <div className={classes.stInner}>
                  <h4>Marine<br/> Construction</h4>
                  <p>
                    We also excel in marine construction. Whether it's rip rap, sea walls, pilings, piers, dredging or 
                    recovery, our skilled professionals are equipped to handle all your waterfront maintenance and construction needs.
                  </p>
                  <div className={classes.btnRow}><Link to="/marine-construction" className={classes.mdLink2}>Learn More</Link></div>
                </div>
              </div>
            </div>
          </div>

          <div className={[classes.imageRow, classes.showOg].join(' ')}>
            <div className={[classes.item, classes.item1].join(' ')}><img src={erPhotoM} alt="Exterior upgrades at Gulf Coast condo" className={classes.item1img} /></div>
            <div className={[classes.item, classes.item2].join(' ')}><img src={mcPhoto} alt="Marine dredging in West Beach Pass" className={classes.item2img} /></div>
          </div>
        </div>

        <div className={[classes.newFlex, classes.hlCareer].join(' ')}>
          <p className={[classes.rotitle2, classes.cust].join(' ')}>Join&nbsp;Us</p>

          <div className={[classes.medDef, classes.class].join(' ')}>
            <div className={[classes.col, classes.right].join(' ')}>
              <div className={classes.clInner}>
                <div className={[classes.hdline].join(' ')}><h4>Interested in working with us?</h4></div>
                <p>We are continually seeking talented and dedicated individuals to join our team. Explore opportunities to partner with MD Thomas Construction.</p>
                <div className={classes.btnRow}><Link to="/careers" className={classes.mdLink2}>Work With Us</Link></div>
              </div>
            </div>

            <div className={[classes.col, classes.left].join(' ')}><img src={fullImage} alt="MD Thomas Construction project in Gulf Shores" className={classes.imgFw} /></div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default home